<template>
<div>
  <vs-row vs-justify="center">
    <vs-col type="flex" vs-justify="center" vs-align="center" vs-lg="12" vs-xs="12">
      <vs-card>
        <h2 class="card-title d-flex">{{$t('FIXTEXT.HEADER.TITEL')}}
          <div class="ml-auto text-right">
            <vs-button  @click="LoadData()" size="small" color="primary" type="filled" icon="refresh"></vs-button>
          </div>
        </h2>
        <p class="card-subtitle">
          <span>{{$t('FIXTEXT.HEADER.TEXT')}}</span>
        </p>
      </vs-card>
    </vs-col>
    <vs-col type="flex" vs-justify="center" vs-align="center" vs-lg="12" vs-sm="12" vs-xs="12">
      <vs-card v-if="editMode" >
        <div id="editFixtextCard" class="vs-con-loading__container">
          <h3 v-if="!newFixtext">{{$t('FIXTEXT.EDIT.HEADER')}}</h3>
          <h3 v-if="newFixtext">{{$t('FIXTEXT.NEW.HEADER')}}</h3>
          <div class="default-input d-flex align-items-center">
            <vs-select :label="$t('FIXTEXT.EDIT.LABEL.DOC_TYPE')" class="mr-3 " v-model="selected.documentType">
              <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item,index) in DocTypes" />
            </vs-select>
            <vs-select :label="$t('FIXTEXT.EDIT.LABEL.SPRACHE')" class="mr-3 " v-model="selected.language_fk">
              <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item,index) in languages" />
            </vs-select>
            <vs-select :label="$t('FIXTEXT.EDIT.LABEL.NR_GRP')" v-model="selected.numbergroup_fk" :danger="numbergroup_fk_fail">
              <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item,index) in numbergroups" />
            </vs-select>
          </div>
          <div class="default-input d-flex align-items-center">
            <vs-input-number v-model="selected.orderNr" :label="$t('FIXTEXT.EDIT.LABEL.ORDER_NR')" class="inputx" min="0"/>
            <vs-checkbox v-model="selected.afterTable"  class="inputx ml-3" >{{$t('FIXTEXT.EDIT.LABEL.AFTER_TABLE')}}</vs-checkbox>
          </div>
          <vs-input :label="$t('FIXTEXT.EDIT.LABEL.HEADER')"  class="inputx mt-2 mb-2" :placeholder="$t('FIXTEXT.EDIT.PLACEHOLDER.HEADER')"  v-model="selected.header" />
          <vs-textarea height="100px" counter="500" :label="$t('FIXTEXT.EDIT.LABEL.TEXT')" :counter-danger.sync="text_fail" v-model="selected.text" />
          <SaveCancel class="mt-3" :SaveAllowed = !FixtextDataChanged v-on:SaveClicked="Save" v-on:CancelClicked="Cancel"></SaveCancel>   
        </div>
      </vs-card>
      <vs-card >
            <div class="d-flex align-items-center">
              <vs-button class="mt-3 mb-2"  type="filled" icon="add" @click="AddFixtext">{{$t('FIXTEXT.ADD')}}</vs-button>
            </div> 
            <div id="fixtextadmin_table" class="vs-con-loading__container" >
              <vs-table
                search
                :data="fixtexte"
                :noDataText="$t('FIXTEXT.NO_DATA')"
                stripe
                max-items="25" 
                pagination
                hoverFlat>

                <template slot="header">
                  <h3>
                    {{$t('FIXTEXT.TABLE.HEADER')}}
                  </h3>
                </template>
                <template slot="thead">                               
                  <vs-th sort-key="orderNr">
                    {{$t('FIXTEXT.TABLE.COL1')}}
                  </vs-th>
                  <vs-th sort-key="text">
                    {{$t('FIXTEXT.TABLE.COL2')}}
                  </vs-th>
                  <vs-th sort-key="header">
                    {{$t('FIXTEXT.TABLE.COL7')}}
                  </vs-th>
                  <vs-th sort-key="language_fk">
                    {{$t('FIXTEXT.TABLE.COL3')}}
                  </vs-th>  
                  <vs-th sort-key="documentType">
                    {{$t('FIXTEXT.TABLE.COL4')}}
                  </vs-th>  
                  <vs-th sort-key="numbergroup_fk">
                    {{$t('FIXTEXT.TABLE.COL5')}}
                  </vs-th> 
                  <vs-th sort-key="afterTable">
                    {{$t('FIXTEXT.TABLE.COL6')}}
                  </vs-th>                                                                                                          
                  <vs-th></vs-th>                                                                                                                                             
                </template>

                <template slot-scope="{data}">
                  <vs-tr :data="tr"  :key="indextr" v-for="(tr, indextr) in data" >   
                    <vs-td :data="data[indextr].orderNr">
                      {{data[indextr].orderNr}}
                    </vs-td>                  
                    <vs-td :data="data[indextr].text">
                      {{LimitTextTo(data[indextr].text,150)}}
                    </vs-td>  
                    <vs-td :data="data[indextr].header">
                      {{data[indextr].header}}
                    </vs-td>     
                    <vs-td :data="data[indextr].language_fk">
                      {{GetNameOfLanguage(data[indextr].language_fk)}}
                    </vs-td> 
                    <vs-td :data="data[indextr].documentType">
                      {{GetDokumentTypeText(data[indextr].documentType)}}
                    </vs-td>  
                    <vs-td :data="data[indextr].numbergroup_fk">
                      {{GetNameOfNumbergroup(data[indextr].numbergroup_fk)}}
                    </vs-td>   
                    <vs-td :data="data[indextr].afterTable">
                      <vs-checkbox v-model="data[indextr].afterTable"  :disabled="true" ></vs-checkbox>
                    </vs-td>                                                                                                
                    <vs-td>
                      <vs-button  @click="EditFixtext(data[indextr].id)" class="ml-2" size="small" color="primary" type="filled" icon="edit"></vs-button>  
                      <vs-button  @click="OpenConfirmDeleteFixtext(data[indextr].id,LimitTextTo(data[indextr].text,20))" class="ml-2" size="small" color="primary" type="filled" icon="delete"></vs-button>                        
                    </vs-td>                    
                                                                                                                                                                                              
                  </vs-tr>
                </template>
              </vs-table>
            </div>           
        </vs-card>
      </vs-col>
  </vs-row>

</div>
</template>

<script>

import SaveCancel from './components/SaveCancel';
import helperMethods from '../helper/staticFuncHelper';
import enumHelper from '../helper/enumHelper';
import {documentType} from '../helper/enumHelper';
import codeHelper from '../helper/staticCodeHelper';

export default {
  name: "Fixtext",
  components: {
    SaveCancel
  },
    data: function (){
      return {
      fixtexte:[],
      selected:[],
      selectedOld:"",
      editMode:false,
      newFixtext:false,
      numbergroup_fk_fail:false,
      text_fail:false,
      numbergroups:[]
      };
  },
  mounted () {
      this.$store.dispatch('numbergroup/getNumbergroupsByCompany',0,{ root: true });
      this.LoadData();
    },
  computed: 
  { 
    getTableData() {
      var data = [];
      if(this.$store.state.fixtext.fixtexts.data != null)
      {
        data = this.$store.state.fixtext.fixtexts.data
      }
      return data;
    },
    loadingTableData () {
      var loading = false;
      if(typeof(this.$store.state.fixtext.fixtexts.status) != 'undefined')
        loading = this.$store.state.fixtext.fixtexts.status.loading;
      return loading;
    },
    FixtextDataChanged(){
        return JSON.stringify(this.selected) != this.selectedOld;
    },
    numbergroupsForSelect(){
      return this.GetListOfNumbergroupsForSelect(-1);
    },
    languages(){
      return this.GetListOfLanguagesForSelect();
    },
    DocTypes(){
      return this.GetListOfDokumentTypeForSelect();
    }
  },
  watch: {
      getTableData(value) {
          this.fixtexte = value;
      },
      loadingTableData(value)
      {
        if(value)
        {
          this.$store.dispatch('alertqueue/showLoader', {'id':'#fixtextadmin_table'});
        }
        else
        {
          this.$store.dispatch('alertqueue/hideLoader', {'id':'#fixtextadmin_table'});
        }
      },
      numbergroupsForSelect(value)
      {
          this.numbergroups = value.numbergroups;
      },
    },
  methods: {    
      ...helperMethods,
      ...enumHelper,
      ...codeHelper,
      LoadData: function ()
      {
          this.$store.dispatch('fixtext/getFixtexts'); 
      },
      EditFixtext(id){
        if(this.$store.state.fixtext.fixtexts.data != null)
        {
          for(var i = 0; i < this.$store.state.fixtext.fixtexts.data.length; i++)
          {
            if(this.$store.state.fixtext.fixtexts.data[i].id==id)
            {
              this.selectedOld = JSON.stringify(this.$store.state.fixtext.fixtexts.data[i]);
              this.selected = JSON.parse(this.selectedOld);
              this.editMode = true;
              break;
            }
          }
        }
      },
      AddFixtext(){
        this.selected={id:0,documentType:documentType.PRICELIST,orderNr:0,language_fk:-1,company_fk:-1,numbergroup_fk:-1,text:"",afterTable:false};
        this.selectedOld = JSON.stringify(this.selected);
        this.editMode = true;
        this.newFixtext = true;
      },
      Save(){
        this.$store.dispatch('alertqueue/showLoader', {'id':'#editFixtextCard'});

        if(typeof(this.selected.orderNr) != 'undefined')
        {
          this.selected.orderNr = Number(this.selected.orderNr);
        }
        this.$store.dispatch('fixtext/saveFixtext', this.selected)
          .then(response => {
            if(response.success == true)
            {
              this.LoadData();
              this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('FIXTEXT.SUCCESS.SAVE')});
              this.editMode = false;
            }
            else
            {
              var text = this.$t('FIXTEXT.ERROR.SAVE');
              text = text.replace("*2*",this.$t(response.text));
              this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':text});
            }
            this.$store.dispatch('alertqueue/hideLoader', {'id':'#editFixtextCard'});
          }, 
          error =>{
            this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('FIXTEXT.ERROR.SAVE')+' '+error});
            this.$store.dispatch('alertqueue/hideLoader', {'id':'#editFixtextCard'});
          });        
      },
      Cancel(){
        this.$data.selected = [];
        this.editMode = false;
        this.newFixtext = false;
      },    
      OpenConfirmDeleteFixtext(id,name) {
        this.$vs.dialog({
          type: "confirm",
          color: "primary",
          title: this.$t('COM.SURE.TITEL'),
          acceptText: this.$t('COM.DELETE'),
          cancelText:this.$t('COM.CANCEL'),
          text: this.$t('FIXTEXT.QUESTION.DELETE').replace("*1*",name),
          accept: this.SendDeleteRequest,
          parameters:{"id": id,"name": name}
        });
      
      },
      SendDeleteRequest: function(parameters)
      {
        this.$store.dispatch('fixtext/deleteFixtext', parameters.id)
        .then(response => {
          if(response.success == true)
          {
            this.LoadData();
            this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('FIXTEXT.SUCCESS.DELETE').replace("*1*",parameters.name)});  
          }
          else
          {
            var text = this.$t('FIXTEXT.WARNING.DELETE').replace("*1*",parameters.name);
            text = text.replace("*2*",this.$t(response.text));
            this.$store.dispatch('alertqueue/addWarning', {'titel':this.$t('COM.WARNING.TITEL'),'text':text}); 
          }
        }, 
        error =>{

            this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('FIXTEXT.ERROR.DELETE').replace("*1*",error)});  
        });
      }
  }
};

</script>